/* Storyliner Icon */

.icon {
  /* skeleton */
  @apply flex w-full;

  /* alignment */
  &.left {
    @apply justify-start;
  }
  &.center {
    @apply justify-center;
  }
  &.right {
    @apply justify-end;
  }

  /* icon */
  svg {
    /* skeleton */
    @apply h-9 w-9 p-0.5;

    /* bg image  */
    @apply bg-marker-anakiwa bg-contain bg-center bg-no-repeat;

    /* color */
    @apply text-black;
  }
}

:global(.dark) .icon {
  svg {
    /* bg image  */
    @apply bg-marker-rouge;

    /* color */
    @apply text-white;
  }
}
